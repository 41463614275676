import {BikiniEvent, AlgoliaRecord} from '../types/bikini'

import React, {useState, useEffect, Dispatch, SetStateAction, useRef} from 'react'
import {graphql, navigate} from 'gatsby'
import HttpError from 'standard-http-error'
import algoliasearch from 'algoliasearch'

import Layout from '../layout'
import Loader from '../images/loader'
import EventTemplate from '../templates/event'
import ErrorTemplate from '../templates/error'
import SuggestionsTemplate from '../templates/suggestions'
import {NetworkError} from '../helpers/api'
import algolia from '../helpers/algolia'
import {intersec} from '../helpers/array'

interface RankedHit {
  hit: AlgoliaRecord
  commonWords: number // slug words in common with current URL slug words
}

const EventPage = ({data, location}) => {
  const [event, setEvent]: [BikiniEvent, Dispatch<SetStateAction<BikiniEvent>>] = useState()
  const [error, setError] = useState<Error>()
  const [suggestions, setSuggestions] = useState<AlgoliaRecord[]>()

  const controller = useRef(typeof AbortController !== 'undefined' ? new AbortController() : null)

  let pathparts: string[]

  const handleSearch = (err: Error, response: algoliasearch.Response<AlgoliaRecord>) => {
    if (err) {
      setError(err)
      throw err // let it bubble up
    }

    const {hits} = response
    if (!hits.length) {
      setError(new HttpError(404))
      return
    }
    if (hits.length === 1) {
      fetchData(hits[0].path)
      return
    }

    const words = pathparts[4].split('-')
    const getCommonWords = ({path}: AlgoliaRecord): number => {
      const pathWords = path.split('/')[4].split('-')
      return intersec(words, pathWords).length
    }
    const ranked: RankedHit[] = hits.map((hit) => ({
      hit,
      commonWords: getCommonWords(hit),
    }))
    ranked.sort((left, right) => {
      if (left.commonWords === right.commonWords) {
        return 0
      }
      return left.commonWords < right.commonWords ? 1 : -1
    })
    if (ranked[0].commonWords > ranked[1].commonWords) {
      // we have a match!
      fetchData(ranked[0].hit.path)
    } else {
      // we can't really know which URL is the closest one so we just show them to the user
      setSuggestions(ranked.map(({hit}) => hit))
    }
  }

  const fetchData = (path: string, searchFallback = false) => {
    if (!searchFallback) {
      // we are now fetching the right path, so we need to update the URL:
      navigate(path, {replace: true})
      // for old events, it won't unmount this component, because there is no known path so it will match the catchall for this matchPath
      // for new events, it will unmount, thus aborting the fetch, and mount the corresponding event page created with createPage
    }
    pathparts = path.split('/')
    const date = new Date(Number(pathparts[1]), Number(pathparts[2]) - 1, Number(pathparts[3]))
    const start = date.getTime() / 1000
    if (isNaN(start)) {
      // ignore non-event URLs
      setError(new HttpError(404))
      return
    }

    const url = `/data/${pathparts[1]}/${pathparts[2]}/${pathparts[3]}/${pathparts[4]}.json`

    fetch(url, {signal: controller.current && controller.current.signal})
      .catch((err) => {
        if (err.name === 'AbortError') {
          throw err
        }
        throw new NetworkError(err.message)
      })
      .then((response) => {
        if (response.status >= 400) {
          throw new HttpError(response.status, response.statusText)
        }
        return response.json().catch(() => {
          throw new HttpError(404)
        })
      })
      .then(setEvent)
      .catch((err) => {
        if (err.name === 'AbortError') {
          // user is leaving the page => component is unmounted
          return
        }
        if (err instanceof HttpError && err.code === 404) {
          if (searchFallback) {
            // Ask Algolia about same day events:
            const params = {
              filters: `date > ${start} AND date < ${start + 86400}`,
            }
            algolia.search(params, handleSearch)
          } else {
            setError(err)
          }
        } else if (err instanceof NetworkError) {
          setError(err)
        } else {
          // should never happen
          setError(err)
          throw err // uncaught => Bugsnag
        }
      })
  }

  useEffect(() => {
    fetchData(location.pathname, true)

    if (controller.current) {
      return () => {
        controller.current.abort()
      }
    }
  }, [])

  if (event) {
    data.bikiniEvent = event
    return <EventTemplate data={data} />
  }

  if (suggestions) {
    return <SuggestionsTemplate suggestions={suggestions} />
  }

  if (error) {
    return <ErrorTemplate error={error} />
  }

  return (
    <Layout>
      <Loader />
    </Layout>
  )
}

export default EventPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        twitter
        hostname
      }
    }
  }
`
