import {AlgoliaRecord} from '../types/bikini'

import React from 'react'
import {ThemeProvider} from 'styled-components'

import Layout from '../layout'
import Event from '../components/event'

interface Props {
  suggestions: AlgoliaRecord[]
}

const SuggestionsTemplate: React.FC<Props> = ({suggestions}) => (
  <Layout title="Page introuvable">
    <h1>Page introuvable</h1>
    <p>Concerts du même jour :</p>
    <ThemeProvider theme={{squares: false}}>
      <>
        {suggestions.map((record) => (
          <Event key={record.objectID} event={record} />
        ))}
      </>
    </ThemeProvider>
  </Layout>
)

export default SuggestionsTemplate
